import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import { Link, graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React from "react";
import Helmet from "react-helmet";
import { useTrackVisibility } from "react-intersection-observer-hook";
import styled from "styled-components";
import media from "styled-media-query";

import Img from "gatsby-image";
import { Page } from "../components";
import RevealWord from "../components/RevealWord";

const Container = styled.main`
  width: 70%;
  max-width: 980px;
  margin: 0 auto;
  margin-top: -10px;

  p {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }

  a {
    font-size: 12px;
    text-transform: lowercase;
    font-weight: 400;
    letter-spacing: 2px;
    cursor: pointer;
    position: relative;
    text-decoration: none;
    padding-left: 10px;

    :hover {
      :after {
        width: 100%;
      }
    }

    :after {
      content: "";
      width: 50%;
      height: 1px;
      position: absolute;
      top: calc(100% + 10px);
      transition: all 800ms ease;
      left: 0;
      background: #8effac;
    }
  }

  ${media.lessThan("medium")`
    width: 100%;
    padding: 64px 16px;
    padding-bottom: 0;
  `}

  .image-container {
    margin: 20px 0;

    ${media.lessThan("medium")`
    width: 100%:
  `}
  }
`;

const Title = styled.h1`
  font-size: 36px;
  font-family: "Krona One", sans-serif !important;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 32px;
  margin-top: 190px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  ${media.lessThan("medium")`
    font-size: 24px;
  `}
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  white-space: pre-wrap;
  :not(:last-child) {
    margin-bottom: 24px;
  }
`;

const CoverMask = styled(motion.div)`
  width: 100%;
  height: 80vh;
  position: relative;
  overflow: hidden;
`;

const CoverWrapper = styled(motion.div)``;

const Cover = styled(Img)`
  width: 100%;
  height: auto;

  ${media.lessThan("medium")`
    height: 100vh;
  `}
`;

const RelatedCases = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 232px;
  padding-top: 100px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding-left: 5%;
  padding-right: 5%;

  ${media.lessThan("medium")`
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 32px;
    margin-top: 32px;
  `}
`;

const Infos = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 45px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  transition: all 800ms ease;

  ${media.lessThan("medium")`
    padding-left: 16px;
  `}

  span {
    font-size: 12px;
    font-weight: 100;
    color: #bebcbc;
    display: flex;
    flex-flow: row wrap;
    transition: all 800ms ease;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0%;

  .image-background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 800ms ease;
  }
`;

const CaseTitle = styled.div`
  font-family: "Krona One", sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  font-variant: small-caps;
  transition: all 400ms ease;

  ${media.lessThan("medium")`
    font-size: 12px;
  `}
`;

const RelatedCase = styled.div`
  width: calc(50% - 10px);
  height: 195px;
  position: relative;
  overflow: hidden;
  margin-left: ${({ isNext }) => (isNext ? "auto" : 0)};

  ${media.lessThan("medium")`
    width: calc(50% - 5px);
    height: 100px;
  `}
  :hover {
    .image-background {
      transform-origin: 50%;
      transform: scale(1.05);
    }

    span {
      transform: translateX(10px);
    }

    ${CaseTitle} {
      transform: translateX(10px);
    }
  }
`;

const Subtitle = styled(motion.div)`
  font-size: 16px;
  font-weight: 300;
  color: #e3e3e3;
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 120px;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 60px;
`;

const InfosHeader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
`;

const BodyImage = (props) => {
  const [ref, { isVisible }] = useTrackVisibility();

  return (
    <motion.div
      ref={ref}
      animate={{
        opacity: isVisible ? 1 : 0,
        y: isVisible ? 0 : 10,
        transition: { type: "spring", delay: 0.5, bounce: 0.1 },
      }}
    >
      <Img className="image-container" {...props} />
    </motion.div>
  );
};

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: (node, ref) => {
      return <BodyImage {...node.data.target} />;
    },
  },
};

const Case = ({ data, pageContext, location }) => {
  const current = data?.allContentfulCases.edges[0]?.node;
  const previous = pageContext.prev;
  const next = pageContext.next;

  const { scrollYProgress } = useViewportScroll();

  const maskParallax = useTransform(scrollYProgress, [0, 2], [0, -1600]);
  const imageParallax = useTransform(scrollYProgress, [0, 2], [-100, 1200]);
  const imageSize = useTransform(scrollYProgress, [0, 2], [1, 2.9]);

  return (
    <Page title={`${current.title}`}>
      {current.backgroundColor && (
        <Helmet>
          <style>{`body { background: ${current.backgroundColor}; }`}</style>
          <meta property="og:url" content={location.href} />
          <meta property="og:title" content={`Lab721 - ${current.title}`} />
          <meta property="og:description" content={current.metaDescriptions} />
          <meta property="og:image" content={current.metaImage?.fluid?.src || current.cover.fluid.src} />
        </Helmet>
      )}
      <HeaderWrapper>
        <InfosHeader>
          <Title>
            <RevealWord delay={1.4}>{current.title}</RevealWord>
          </Title>

          <Subtitle
            initial={{ opacity: 0, y: 10 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { bounce: 0.9, delay: 1.8 },
            }}
          >
            {current.services.map((item) => item.title).join(" - ")}
          </Subtitle>
        </InfosHeader>

        <CoverMask
          initial={{ y: 40 }}
          animate={{
            y: 0,
            transition: {
              type: "spring",
              delay: 1.5,
              stiffness: 30,
              damping: 13,
            },
          }}
          exit={{ y: -30, opacity: 0 }}
        >
          <CoverWrapper
            style={{ scale: imageSize }}
            initial={{ y: -120, opacity: 0 }}
            animate={{
              opacity: 1,
              y: -10,
              transition: {
                type: "spring",
                delay: 1.5,
                stiffness: 30,
                damping: 13,
              },
            }}
          >
            <Cover fluid={current.cover.fluid} />
          </CoverWrapper>
        </CoverMask>
      </HeaderWrapper>

      <Container>{renderRichText(current.description, options)}</Container>

      <RelatedCases>
        {previous && (
          <RelatedCase as={Link} to={`/cases/${previous.slug}/`}>
            <ImageWrapper>
              <Img className="image-background" fluid={previous.cover.fluid} />
            </ImageWrapper>
            <Infos>
              <div>
                <span>Anterior</span>
                <CaseTitle>{previous.title}</CaseTitle>
              </div>
            </Infos>
          </RelatedCase>
        )}

        {next && (
          <RelatedCase as={Link} to={`/cases/${next.slug}/`} isNext>
            <ImageWrapper>
              <Img className="image-background" fluid={next.cover.fluid} />
            </ImageWrapper>

            <Infos>
              <div>
                <span>Próximo</span>
                <CaseTitle>{next.title}</CaseTitle>
              </div>
            </Infos>
          </RelatedCase>
        )}
      </RelatedCases>
    </Page>
  );
};

export const query = graphql`
  query($slug: String!) {
    allContentfulCases(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          title
          backgroundColor
          metaDescriptions
          cover {
            fluid(maxWidth: 1920) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          metaImage {
            fluid(maxWidth: 1920) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          services {
            id
            title
          }
          description {
            raw
            references {
              ... on ContentfulAsset {
                __typename
                contentful_id
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Case;
